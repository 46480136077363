import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next";
import { downloadBookingDetails, downloadBookingDetailsPdf, getAllEventByOrganizer, getAllEvents, getAllOrganizer, getBookingByEventId, getBookingByVenueIdAndEventId, getEventVenuesBySlug, getForumBySlug } from "api";
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment";

import { AgGridReact } from 'ag-grid-react';
import { GetContextMenuItems, RowDoubleClickedEvent } from "ag-grid-community";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import OverviewChart from "./Chart";

const pagination = true;
const paginationPageSize = 500;
const paginationPageSizeSelector = [200, 500, 1000];
const AttendeeReport = props => {
    //meta title
    document.title = "Booking List | EventoX";
    const [eventList, setEventList] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [bookingList, setBookingList] = useState([])
    const [mainBookingList, setMainBookingList] = useState([])
    const [getVenueList, setGetVenueList] = useState([])
    const [selectedVenue, setSelectedVenue] = useState(null)
    const [reports, setReports] = useState([
       
        { title: "No. of Attendees", iconClass: "bx-copy-alt", description: 0 },
        { title: "No. of Tickets", iconClass: "bx-archive-in", description: 0 },
        // { title: "No. of Adult", iconClass: "bx-archive-in", description: 0 },
        // { title: "No. of Children above 5", iconClass: "bx-archive-in", description: 0 },
        // { title: "No. of Children below 5", iconClass: "bx-archive-in", description: 0 },

        {
            title: "Total Revenue",
            iconClass: "bx-purchase-tag-alt",
            description: 0,
        },
        { title: "Success Bookings", iconClass: "bx-copy-alt", description: 0 },
        { title: "Failed Bookings", iconClass: "bx-copy-alt", description: 0 },
        { title: "Cancelled Bookings", iconClass: "bx-copy-alt", description: 0 },
    ])

    useEffect(() => {
        fetchAll()
    }, [])
    

    const fetchAll = async () => {
        try {

            const respAllEvent = await getAllEventByOrganizer();
            const eventOptions = respAllEvent.map((item) => {
                return {
                    value: item.eventId,
                    label: item.eventName,
                    slugId: item.slugId
                }
            })
            setEventList(eventOptions);





        } catch (error) {
            console.log(error);
        }
    }
    

    const fetchBookingByEventVenue = async (venueId) => {
        let getBookingResp = [];
        if(venueId==0)
            getBookingResp = await getBookingByEventId(selectedEvent.value)
        else
            getBookingResp = await getBookingByVenueIdAndEventId(venueId,selectedEvent.value)
        const activeBooking = getBookingResp.filter((item) => item.bookingStatus === 'confirmed');
        const failedBooking = getBookingResp.filter((item) => item.bookingStatus === 'failed');
        const cancelledBooking = getBookingResp.filter((item) => item.bookingStatus === 'cancelled');
        const totalBooking = getBookingResp.length;
        const currency = activeBooking[0]?.currency
        setBookingList(activeBooking)

        const ticketPurchased = activeBooking.filter((item) => item.bookedBy === "0")
        setMainBookingList(ticketPurchased)
        const totalRevenue = ticketPurchased.reduce((acc, item) => acc + item.totalTicketAmount, 0)

        let totalAdultBooking = 0;
        let totalChildBooking = 0;
        let totalSeniorBooking = 0;
        ticketPurchased.forEach((item) => {
            if (item.bookingObject && item.bookingObject.ticketListCount) {
                console.log("🚀 ~ fetchBookingByEvent ~ item.bookingObject.ticketListCount", item.bookingObject.ticketListCount[0]?.count)
                totalAdultBooking += parseInt(item.bookingObject.ticketListCount[0]?.count || 0, 10);
                console.log("🚀 ~ fetchBookingByEvent ~ totalAdultBooking:", totalAdultBooking)

                totalSeniorBooking += parseInt(item.bookingObject.ticketListCount[1]?.count || 0, 10);
                totalChildBooking += parseInt(item.bookingObject.ticketListCount[2]?.count || 0, 10);
            }

        })
        // const totalChildBooking = getBookingResp.filter((item) => item.bookingObject.selectedTicket[0].ticketType === 'Child')
        // const totalSeniorBooking = getBookingResp.filter((item) => item.bookingObject.selectedTicket[0].ticketType === 'Senior')
        setReports([
            { title: "No. of Attendees", iconClass: "bx-copy-alt", description: `${activeBooking.length}` },
            { title: "No. of Tickets", iconClass: "bx-archive-in", description: ticketPurchased.length },
            
            // { title: "No. of Adult", iconClass: "bx-archive-in", description: totalAdultBooking },
            // { title: "No. of Children above 5", iconClass: "bx-archive-in", description: totalSeniorBooking },
            // { title: "No. of Children below 5", iconClass: "bx-archive-in", description: totalChildBooking },

            {
                title: "Total Revenue",
                iconClass: "bx-purchase-tag-alt",
                description: `${currency} ${totalRevenue}`,
            },
            { title: "Success Bookings", iconClass: "bx-copy-alt", description: activeBooking.length },
            { title: "Failed Bookings", iconClass: "bx-copy-alt", description: failedBooking.length },
            { title: "Cancelled Bookings", iconClass: "bx-copy-alt", description: cancelledBooking.length },
        ])

        console.log("🚀 ~ fetchBookingByEvent ~ getBookingResp:", getBookingResp)
    }
    const fetchVenueByEvent = async (eventData) => {
        console.log("🚀 ~ fetchVenueByEvent ~ eventData:", eventData)
        const getVenueList = await getEventVenuesBySlug(eventData.slugId)
        const venueObj={
            value: 0,
            label: "All Venues"
        }
        const venueOptions = getVenueList.map((item) => {
            return {
                value: item.venueId,
                label: item.venueName
            }
        })
        setGetVenueList([venueObj, ...venueOptions])

        console.log("🚀 ~ fetchVenueByEvent ~ getVenueList:", getVenueList)
        console.log("🚀 ~ fetchVenueByEvent ~ venueOptions:", venueOptions)


    }
    const columns = useMemo(
        () => [

            // {
            //     headerName: 'Booked By',
            //     cellRenderer: params => {
            //         return params.data.bookingObject.primaryContactDetails.firstName + ' ' + params.data.bookingObject.primaryContactDetails.lastName
            //     },
            //     filter: true

            // },


            {
                headerName: 'Name',
                field: 'Contact.firstName',
            },
            {
                headerName: 'Email',
                field: 'Contact.email',
                filter: true

            },
            {
                headerName: 'Phone No',
                field: 'Contact.phoneNo',
                filter: true

            },
            {
                headerName: 'Ticket',
                cellRenderer: params => {
                    return params.data.TicketType?.ticketType
                }

            },
            {
                headerName: 'Ticket Count',
                field: 'totalTicketCount',


            },
          
            {
                headerName: 'Discount',
                field: 'discountAmount',
            },


            {
                headerName: 'Total Amount',
                cellRenderer: params => {
                    return params.data.totalTicketAmount
                },

            },
            {
                headerName: 'Booking Date',
                cellRenderer: params => {
                    return moment(params.data.createdAt).format('DD-MMM-YYYY HH:mm:ss')
                },

            },



        ])
    const sideBar = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
        defaultToolPanel: 'filters',
    };

    const getContextMenuItems = React.useCallback(({ node }) => {
        const defaultItems = [
            'copy',        // Default copy option
            'paste',       // Default paste option (works with Excel-style editing)
            'export',      // Export menu with Excel and CSV options
            'separator',   // Separator line
        ];


        return [...defaultItems];
    }, [])

    const onClickDownload = async (type) => {
        const venueId = selectedVenue?.value || 0;
        if(type==="excel")
            await downloadBookingDetails(selectedEvent.value,venueId);
        else if(type==="pdf"){
            await downloadBookingDetailsPdf(selectedEvent.value,venueId);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Dashboards")}
                        breadcrumbItem={props.t("Dashboard")}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xl="6">
                                            <div className="col-md-12">
                                                <Select
                                                    value={selectedEvent}
                                                    placeholder="Select Event"
                                                    onChange={(data) => {
                                                        setSelectedEvent(data)
                                                        fetchVenueByEvent(data)
                                                    }}
                                                    options={eventList}
                                                    width="100%"
                                                    className="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="col-md-12">
                                                <Select
                                                    value={selectedVenue}
                                                    placeholder="Select Event"
                                                    onChange={(data) => {
                                                        setSelectedVenue(data)
                                                        fetchBookingByEventVenue(data.value)
                                                    }}
                                                    options={getVenueList}
                                                    width="100%"
                                                    className="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12 mt-100">
                            <Row>
                                {(reports || [])?.map((report, key) => (
                                    <Col md="4" key={"_col_" + key}>
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium">
                                                            {report.title}
                                                        </p>
                                                        <h4 className="mb-0">{report.description}</h4>
                                                    </div>
                                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            <i className={"bx " + report.iconClass + " font-size-24"}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col lg="12">

                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                       
                                        <Col xl="12">
                                            <div className="text-lg-end mt-3 mt-lg-0">
                                                <UncontrolledDropdown
                                                >
                                                    <DropdownToggle color="primary" type="button">
                                                        <i className="bx bx-download me-2"></i> Export <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>

                                                        <DropdownItem href="#" onClick={()=>onClickDownload("excel")}>Export Excel</DropdownItem>
                                                        <DropdownItem href="#" onClick={()=>onClickDownload("pdf")}>Export PDF</DropdownItem>

                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                               
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <div style={{
                                            height: '500px',
                                            width: '100%'
                                        }} className="ag-theme-quartz">
                                            <AgGridReact
                                                columnDefs={columns}
                                                rowData={mainBookingList}
                                                sideBar={sideBar}
                                                rowGroupPanelShow="always"
                                                pagination={pagination}
                                                paginationPageSize={paginationPageSize}
                                                paginationPageSizeSelector={paginationPageSizeSelector}
                                                getContextMenuItems={getContextMenuItems}

                                            />
                                        </div>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <OverviewChart dataColors='["--bs-warning"]' bookingList={mainBookingList} />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default withTranslation()(AttendeeReport);

